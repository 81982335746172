var exports = {};

exports = function (hljs) {
  var XML_IDENT_RE = "[A-Za-z0-9\\._:-]+";
  var TAG_INTERNALS = {
    endsWithParent: true,
    illegal: /</,
    relevance: 0,
    contains: [{
      className: "attr",
      begin: XML_IDENT_RE,
      relevance: 0
    }, {
      begin: /=\s*/,
      relevance: 0,
      contains: [{
        className: "string",
        endsParent: true,
        variants: [{
          begin: /"/,
          end: /"/
        }, {
          begin: /'/,
          end: /'/
        }, {
          begin: /[^\s"'=<>`]+/
        }]
      }]
    }]
  };
  return {
    aliases: ["html", "xhtml", "rss", "atom", "xjb", "xsd", "xsl", "plist", "wsf"],
    case_insensitive: true,
    contains: [{
      className: "meta",
      begin: "<!DOCTYPE",
      end: ">",
      relevance: 10,
      contains: [{
        begin: "\\[",
        end: "\\]"
      }]
    }, hljs.COMMENT("<!--", "-->", {
      relevance: 10
    }), {
      begin: "<\\!\\[CDATA\\[",
      end: "\\]\\]>",
      relevance: 10
    }, {
      className: "meta",
      begin: /<\?xml/,
      end: /\?>/,
      relevance: 10
    }, {
      begin: /<\?(php)?/,
      end: /\?>/,
      subLanguage: "php",
      contains: [// We don't want the php closing tag ?> to close the PHP block when
      // inside any of the following blocks:
      {
        begin: "/\\*",
        end: "\\*/",
        skip: true
      }, {
        begin: "b\"",
        end: "\"",
        skip: true
      }, {
        begin: "b'",
        end: "'",
        skip: true
      }, hljs.inherit(hljs.APOS_STRING_MODE, {
        illegal: null,
        className: null,
        contains: null,
        skip: true
      }), hljs.inherit(hljs.QUOTE_STRING_MODE, {
        illegal: null,
        className: null,
        contains: null,
        skip: true
      })]
    }, {
      className: "tag",

      /*
      The lookahead pattern (?=...) ensures that 'begin' only matches
      '<style' as a single word, followed by a whitespace or an
      ending braket. The '$' is needed for the lexeme to be recognized
      by hljs.subMode() that tests lexemes outside the stream.
      */
      begin: "<style(?=\\s|>|$)",
      end: ">",
      keywords: {
        name: "style"
      },
      contains: [TAG_INTERNALS],
      starts: {
        end: "</style>",
        returnEnd: true,
        subLanguage: ["css", "xml"]
      }
    }, {
      className: "tag",
      // See the comment in the <style tag about the lookahead pattern
      begin: "<script(?=\\s|>|$)",
      end: ">",
      keywords: {
        name: "script"
      },
      contains: [TAG_INTERNALS],
      starts: {
        end: "</script>",
        returnEnd: true,
        subLanguage: ["actionscript", "javascript", "handlebars", "xml", "vbscript"]
      }
    }, {
      className: "tag",
      begin: "</?",
      end: "/?>",
      contains: [{
        className: "name",
        begin: /[^\/><\s]+/,
        relevance: 0
      }, TAG_INTERNALS]
    }]
  };
};

export default exports;